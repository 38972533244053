<template>
  <div>
    <v-chip-group>
      <v-chip
        v-for="keyItem in keyItems"
        :key="keyItem['value']"
        :color="colors[keyItem['value']]"
        dark
      >
        <b>{{ keyItem['value'] }}</b>
      </v-chip>
    </v-chip-group>
  </div>
</template>

<script>
  export default{
    name: 'ChipKey',
    props: {
      options: Array,
      filter: String,
      colors: Object
    },
    data(){
      return{
        keyItems: []
      }
    },
    mounted: function(){
      let filt = this.filter;
      this.keyItems = this.options.filter(function(option){
        return option['filter'] == filt;
      })
    }
  }
</script>

<style scoped>
.v-chip-group{
  width: 100%;
  justify-content: center;
}
</style>
