<template>
  <div>
    <v-row>
      <v-col
        cols="0"
        lg="1"
      />
      <v-col
        cols="12"
        lg="10"
      >
        <v-card>
          <v-tabs v-model="tab"
            background-color="primary"
            dark>
            <v-tab v-for="head in headers"
              :key="head">
              {{ head }}
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item
              v-for="(head, headName) in headers"
              :key="headName"
            >
              <v-simple-table>
                <thead/>
                <tbody>
                  <tr v-for="(val, key) in metrics[head]" :key="key">
                    <td><b> {{ key }}</b></td>
                    <td> {{ val }} </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default{
  name: "TableView",
  props: {
    metricsFile: Object,
    timestamp: String
  },
  data() {
    return {
      metrics: Object,
      headers: ["Risk Analysis","Modularity Analysis"],
      tab: null
    }
  },
  mounted: function() {
    this.updateTable();
  },
  watch: {
    timestamp: function(){
      this.updateTable();
    }
  },
  methods: {
    updateTable(){
      this.metrics = this.metricsFile[this.timestamp];
      delete this.metrics["Risk Analysis"]["Average Removals Needed"];
    }
  }
}
</script>
