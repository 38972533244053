<template>
  <div>
    <v-card>
      <v-tabs v-model="cytoTab"
        background-color="orange darken-3"
        dark
      >
        <v-tab> Counts </v-tab>
        <v-tab> Importance </v-tab>
      </v-tabs>
      <v-tabs-items v-model="cytoTab">
        <v-tab-item v-for="item in cytoItems" :key="item">
          <div v-if="item=='Counts'">
            <v-simple-table dense>
              <thead/>
              <tbody>
                <tr>
                  <td><b>Density</b></td>
                  <td>{{ density }}%</td>
                </tr>
                <tr v-if="intraStateLinks">
                  <td><b>Percent of In-State Investments</b></td>
                  <td> {{ intraStateLinks }}%</td>
                </tr>
                <tr>
                  <td colspan="2"><b>Number of nodes</b></td>
                </tr>
                <tr v-for="(val, key) in counts" :key="key">
                  <td>{{ key }}</td>
                  <td>{{ val }}</td>
                </tr>
              </tbody>
            </v-simple-table>
          </div>
          <div v-if="item=='Importance'">
            <v-simple-table dense>
              <thead/>
              <tbody>
                <tr v-for="node in mostCentral" :key = "node[0]">
                  <td>{{ node[0] }}</td>
                  <td>{{ node[1] }}</td>
                </tr>
              </tbody>
            </v-simple-table>
          </div>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
    <br />
    <v-card v-if="scale">
      <v-row>
        <v-col cols="3"/>
        <v-col cols="6">
          <v-text-field
            v-model="tempScale"
            single-line
            :min="0"
            :max="1"
            :step="0.05"
            type="number"
          />
        </v-col>
      </v-row>
        <v-btn @click="syncScale">
          Update scale
        </v-btn>
    </v-card>
  </div>
</template>

<script>
import cytoscape from 'cytoscape';

export default{
  name: 'CytoMetrics',
  props: {
    cytoData: Array,
    scale: String,
    Name: String,
  },
  data(){
    return{
      nodes: Array,
      links: Array,
      counts: {},
      mostCentral: [],
      cytoTab: null,
      cytoItems: ['Counts','Importance'],
      density: 0,
      tempScale: 0.75,
      scaleWeight: 0.75,
      intraStateLinks: 0,
    }
  },
  created: function(){
    this.nodes = this.cytoData[0];
    this.links = this.cytoData[1];
  },
  mounted: function(){ // TODO: Delete this?
    //this.create_cytoscape();
  },
  watch: {
    cytoData: function(){
      this.counts = {};
      this.mostCentral = [];
      this.nodes = this.cytoData[0];
      this.links = this.cytoData[1];
      this.create_cytoscape();
    },
    scaleWeight: function(){
      this.counts = {};
      this.mostCentral = [];
      this.create_cytoscape();
    }
  },
  methods: {
    syncScale(){
      this.scaleWeight = this.tempScale;
    },
    create_cytoscape(){
      /**
        * Creates the cytoscape instance; runs the centrality metric algorithms;
        * sorts the nodes by most central; emits the sorted nodes; and calculates
        * the in-state to out-of-state link ratio
      **/
      var cy = cytoscape()
      var network = {
        "nodes": this.nodes,
        "links": this.links
      };
      var biggest = 0;
      var smallest = 0;
      for(var i in network.nodes){
        let curr = network.nodes[i];
        cy.add({data: curr});
        // Check current value for extremas
        // TODO: Tidy this up some, maybe make it is own function
        if (curr[this.scale] != 0 && !isNaN(curr[this.scale])){
          if(smallest == 0){
            smallest = curr[this.scale];
          }
          if (curr[this.scale] > biggest){
            biggest = curr[this.scale];
          }
          if (curr[this.scale] < smallest){
            smallest = curr[this.scale];
          }
        }
      }
      for(var j in network.links){
        let curr = network.links[j];
        cy.add({data: curr});
      }
      let dcn = cy.elements().degreeCentralityNormalized({});
      this.counts["Total"] = 0;
      // Setting an arbitrary cutoff, we can stress test this value later
      if (this.nodes.length < 1001){
          let ccn = cy.elements().closenessCentralityNormalized({});
          let bc = cy.elements().betweennessCentrality();
          cy.nodes().forEach( n => {
            this.counts["Total"] += 1;
            let scale = 0;
            if(this.scale){
              // if there's a scale, scale by it
              // TODO: Round this down a little
              if (n.data(this.scale)!= 0 && !isNaN(n.data(this.scale))){
                scale = (n.data(this.scale)-smallest)/(biggest-smallest);
                scale = scale.toFixed(2);
              }
              n.data({
                dcn: dcn.degree(n),
                ccn: ccn.closeness(n),
                bc: bc.betweennessNormalized(n),
                importance: (Math.round(this.scaleWeight * scale *100)/100 + Math.round(0.25*(dcn.degree(n) + ccn.closeness(n) + bc.betweennessNormalized(n))/3*100)/100).toFixed(2)
              });
            }
            else{
              // else don't use scale
              n.data({
                dcn: dcn.degree(n),
                ccn: ccn.closeness(n),
                bc: bc.betweennessNormalized(n),
                importance: Math.round((dcn.degree(n) + ccn.closeness(n) + bc.betweennessNormalized(n))/3*100)/100
              });
            }

            if (n.data("role") in this.counts){
              this.counts[n.data("role")] += 1;
            }
            else{
              this.counts[n.data("role")] = 1;
            }
          })
      }
      else {
        cy.nodes().forEach( n => {
          this.counts["Total"] += 1;
          let scale = 0;
          if(this.scale){
            // TODO: Round this down a little
            if (n.data(this.scale)!= 0 && !isNaN(n.data(this.scale))){
              scale = (n.data(this.scale)-smallest)/(biggest-smallest);
              scale = scale.toFixed(2);
            }
            n.data({
              dcn: dcn.degree(n),
              importance: (Math.round(this.scaleWeight * scale * 100)/100 + Math.round(0.25 * dcn.degree(n)*100)/100).toFixed(2)
            });
          }
          else{
            // else don't use scale
            n.data({
              dcn: dcn.degree(n),
              importance: Math.round(dcn.degree(n)*100)/100
            });
          }
          if (n.data("role") in this.counts){
            this.counts[n.data("role")] += 1;
          }
          else{
            this.counts[n.data("role")] = 1;
          }
        })
      }
      var mostCent = cy.nodes().sort(function( a, b ){
        return a.data('importance') < b.data('importance') ? 1 : -1;
      } );
      let sortedNodes = [];
      mostCent.forEach(n => {
        n._private.data.id = parseInt(n._private.data.id);
        sortedNodes.push(n._private.data);
      });
      this.$emit('sorted-nodes', sortedNodes);
      mostCent = mostCent.slice(0,10);
      mostCent.forEach( n => this.mostCentral.push([n.data('name'),n.data('importance')]));
      let n = this.nodes.length;
      this.density = Math.round((this.links.length*2/(n*(n-1)))*10000)/100;
      if(this.Name === 'Michigan Network'){
        let inState = 0;
        let outState = 0;
        cy.edges().forEach((link)=> {
          let sourceFip = link._private.source.data('county_id');
          let targetFip = link._private.target.data('county_id');
          if (sourceFip.length == targetFip.length && sourceFip.length == 5){
            if (sourceFip.slice(0,2) == targetFip.slice(0,2)) {
              inState += 1;
            }
            else {
              outState += 1;
            }
          }
        });
        // Division by zero doesn't matter (will result in NaN and not display)
        this.intraStateLinks = (inState / (inState + outState) * 100).toFixed(2);
      }
    }
  }
}
</script>
