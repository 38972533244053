<template>
  <div class="dashboard">
    <v-sheet min-height="20"/>
    <v-row>
      <v-col
        cols="0"
        lg="1"/>
      <v-col cols="2">
        <v-select
          :items="networks"
          v-model="selected"
          :value="selected"
          outlined
          @change="set_data"
        />
      </v-col>
      <v-col cols="2" />
      <v-col v-if="selected==='Startup Network'" cols ="4">
        <b>For a live demo of these and other networks, or for questions, please contact us.</b>
      </v-col>
    </v-row>
    <VisualizationTabs
      v-if="SchemaJson && NetworkJson && GeoJson && MetricsJson"
      :SchemaJson="SchemaJson"
      :NetworkJson="NetworkJson"
      :MapJson="MapJson"
      :GeoJson="GeoJson"
      :MetricsJson="MetricsJson"
      :Name ="selected"
    />
  </div>
</template>

<script>
import { Auth } from 'aws-amplify';
import { getData } from "../datafetch.js";
import { AUTH_ENABLED } from "../constants.js";

import VisualizationTabs from "../components/VisualizationTabs.vue";

export default {
  name: 'Home',
  components: {
    VisualizationTabs
  },
  data(){
    return{
      networks: ['Startup Network'],
      selected: 'Startup Network',
      accessToken: null,
      SchemaJson: null,
      NetworkJson: null,
      MapJson: null,
      GeoJson: null,
      MetricsJson: null
    }
  },
  created: async function(){
    /**
      * On create, get access tokens; set the network options; set the data
    **/
    if (AUTH_ENABLED) {
      let token = await Auth.currentSession();
      this.accessToken = token["accessToken"];
    }
    this.set_options();
    await this.set_data();
  },
  methods: {
    set_options(){
      /**
        * Gets the user's groups from cognito and populate their network options
        * based on permissions
      **/
      Auth.currentAuthenticatedUser()
        .then( user => {
          let groups = user.signInUserSession.idToken.payload['cognito:groups'];
          if (groups.includes('Pitchbook_Access')){
            this.networks.push('Investment Network');
          }
          if (groups.includes('Partner_Access')){
            this.networks.push('Partner Network');
          }
          if (groups.includes('Michigan_Access')){
            this.networks.push('Michigan Network');
          }
        });
    },
    async set_data(){
      /**
        * Given the value of selected, fetch all data files pertaining to said
        * network and pass them to child component props
      **/
      this.NetworkJson = null;
      this.MetricsJson = null;
      this.MapJson = null;
      // load the topology file
      // TODO: This GeoJson doesn't have to be fetched on network change, pull it out?
      this.GeoJson = await getData('us.json', this.accessToken);
      //set variables based on selected network
      let schemaFile = "";
      let networkFile = "";
      let mapFile = "";
      let metricsFile = "";
      if (this.selected == "Startup Network"){
        schemaFile = 'EIN_Orgs_schema.json';
        networkFile = 'EIN_Orgs_connected.json';
        mapFile = 'EIN_orgs_geo.json';
        metricsFile = 'EIN_Orgs-timeseries-metrics.json';
      }
      else if (this.selected == "Investment Network"){
        schemaFile = 'Pitchbook_Orgs_schema.json';
        networkFile = 'ThirdConnections.json';
        mapFile = 'ThirdConnections.json';
        metricsFile = 'ThirdConnections-timeseries-metrics.json';
      }
      else if (this.selected == "Partner Network"){
        schemaFile = 'Partner_Network_schema.json';
        networkFile = 'Partner_Network.json';
        mapFile = 'Partner_Network.json';
        metricsFile = 'Partner_Network-timeseries-metrics.json';
      }
      else if (this.selected == "Michigan Network"){
        schemaFile = 'Michigan_Network_Schema.json';
        networkFile = 'Michigan_Network.json';
        mapFile = 'Michigan_Network.json';
        metricsFile = 'Michigan_Network-timeseries-metrics.json';
      }
      // load the schema file
      this.SchemaJson = await getData(schemaFile, this.accessToken);
      // load the network file
      this.NetworkJson = await getData(networkFile, this.accessToken);
      // load the mapNodes file
      this.MapJson = await getData(mapFile, this.accessToken);
      // load the metrics file
      this.MetricsJson = await getData(metricsFile, this.accessToken);
    }
  },
}
</script>
