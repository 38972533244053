<template>
  <div>
    <div id = "container">
      <v-card >
        <div id="tooltip"
          class="tooltip"
        />
      </v-card>
      <svg id = "network" :height="height" :width="width">
        <g id="linksG"/>
        <g id="nodesG"
          @mouseover="showTooltip"
          @mouseout="hideTooltip"
          @click="toggleTooltipClick"
        />
      </svg>
    </div>
  </div>
</template>

<script>
import * as d3 from "d3";

export default{
  name: "NetworkView",
  props: {
    data: Array,
    searchString: String,
    colors: Object
  },
  data() {
    return {
      height: 600,
      width: 960,
      linkedByIndex: {},
      network: Object,
      nodes: Array,
      links: Array,
      clicked: false
    }
  },
  created: function(){
    /**
      * On create, set the nodes and links based on props; map the nodes; map the links
    **/
    this.nodes = this.data[0];
    this.links = this.data[1];
    const nodesMap = this.mapNodes(this.nodes);
    this.linkedByIndex = this.mapLinks(this.links, nodesMap);
  },
  mounted: function(){
    /**
      * On mount, create the network based on the data fields
    **/
    this.hideTooltip();
    this.network = new this.Network(this.nodes, this.links, this.colors, this.height, this.width);
  },
  watch: {
    data: function() {
      this.nodes = this.data[0];
      this.links = this.data[1];
      this.updateNetwork();
      this.updateSearch(this.searchString);
    },
    searchString: function(){
      this.updateSearch(this.searchString);
    }
  },
  methods: {
    showTooltip(event) {
      /**
        * Show the tooltip for a hovered-over node if the click flag hasn't been raised
        * @param {WindowEvent} event: The event of an element being hovered over
      **/
      if(!this.clicked){
        let tooltip = document.getElementById("tooltip");
        tooltip.innerHTML = event.target.textContent;
        tooltip.style.display = 'block';
      }
    },
    hideTooltip() {
      /**
        * Hides the tooltip for the current node if the click flag hasn't been raised
      **/
      if(!this.clicked){
        var tooltip = document.getElementById("tooltip");
        tooltip.innerHTML = '';
        tooltip.style.display = "none";
      }
    },
    toggleTooltipClick(event){
      /**
        * Toggles the click flag, making tooltip information permanent when raised
        * and hidden when lowered
        * @param {WindowEvent} event: The event of an element being hovered over
      **/
      if(this.clicked){
        this.clicked=false;
        this.hideTooltip();
      }
      else{
        this.clicked=true;
        this.showTooltip(event)
      }
    },
    updateNetwork() {
      /**
        * Recreates the network, invoked when a prop has changed and the current
        * network is out of date
      **/
      this.network = new this.Network(this.nodes, this.links, this.colors, this.height, this.width);
    },
    mapNodes (nodes) {
      /**
        * Maps nodes such that they're accessable by id, not a list index
        * @param {Node[]} nodes: The list of nodes needing to be mapped
        * @return {Map{id->Node}}: A map where the index is an integer and the value is a Node
      **/
      var nodesMap = new Map();
      nodes.forEach(n => nodesMap.set(n.id, n));
      return nodesMap;
    },
    mapLinks (links, nodesMap) {
      /**
        * Maps all links such that the existence of a link "source,target" can be
        * confirmed in constant time
        * @param {Link[]} links: The list of all links passed from the parent
        * @param {Map{id->Nodes}} nodesMap: The map of all nodes
        * @return {Map{id,id->Number}}: A map of link endpoints to a 1, confirming existance
      **/
      var rtn = {};
      links.forEach(function(l) {
        var source = nodesMap.get(l.source);
        var target = nodesMap.get(l.target);
        // linkedByIndex is used for link sorting
        rtn[`${source.id},${target.id}`] = 1;
      });
      return rtn;
    },
    updateSearch(searchTerm){
      /**
        * Matches the user-specified string against all names of nodes in the network,
        * highlighting the matching nodes
        * @param {String} searchTerm: The user-entered string being searched
      **/
      let node = d3.select("#nodesG")
        .selectAll("circle");
      let colors = this.colors;
      const searchRegEx = new RegExp(searchTerm.toLowerCase());
      node.each(function(d) {
        let match;
        const element = d3.select(this);
        match = d.name.toLowerCase().search(searchRegEx);
        if ((searchTerm.length > 2) && (match >= 0)){
          element.style("fill", "#FFFF00")
            .style("stroke-width", 5.0)
            .style("stroke", "#555")
            .attr("r",20);
          return d.searched = true;
        }
        else {
          element.style("fill", d => colors[d.role])
            .style("stroke-width", 1.0)
            .attr("r", d=> d.size*2);
          return d.searched = false;
        }
      });
    },
    Network(nodes, links, colors, height, width) {
      /**
        *
        * @param
        * @param
        * @param
        * @param
        * @param
      **/
      this.networkLinks = links.map(d => Object.create(d));
      this.networkNodes = nodes.map(d => Object.create(d));
      this.clicked = false;
      this.drag = simulation => {
        function dragstarted(event, d) {
          if (!event.active) simulation.alphaTarget(0.3).restart();
          d.fx = d.x;
          d.fy = d.y;
        }

        function dragged(event,d) {
          d.fx = event.x;
          d.fy = event.y;
        }

        function dragended(event,d) {
          if (!event.active) simulation.alphaTarget(0);
          d.fx = null;
          d.fy = null;
        }

        return d3.drag()
            .on("start", dragstarted)
            .on("drag", dragged)
            .on("end", dragended);
      }

      this.simulation = d3.forceSimulation(this.networkNodes)
          .force("link", d3.forceLink(this.networkLinks).id(d => d.id))
          .force("charge", d3.forceManyBody())
          .force("x", d3.forceX())
          .force("y", d3.forceY());
      this.svg = d3.select("#network")
          .attr("viewBox", [-width / 2, -height / 2, width, height]);
      this.link = d3.select("#linksG")
          .attr("stroke", "#999")
          .attr("stroke-opacity", 0.6)
          .selectAll("line")
          .data(this.networkLinks)
          .join("line")
          .attr("stroke-width", d => 'lead' in d ? (d.lead.leadNow === 'true' ? 5 : 2) : 2);
      this.node = d3.select("#nodesG")
          .attr("stroke", "#aaa")
          .attr("stroke-width", 1.5)
          .selectAll("circle")
          .data(this.networkNodes)
          .join("circle")
          .attr("r", d => d.size*2)
          .attr("fill", d => colors[d.role])
          .text(d=> d.tooltip)
          .call(this.drag(this.simulation));

      // Mouseover tooltip function
      this.showDetails = function(d) {
          // higlight connected links
          if (this.link) {
            this.link.attr("stroke", function(l) {
              if ((l.source === d) || (l.target === d)) { return "#555"; } else { return "#ddd"; }
            })
              .attr("stroke-opacity", function(l) {
                if ((l.source === d) || (l.target === d)) { return 1.0; } else { return 0.5; }
              });
          }
        // highlight neighboring nodes
        // watch out - don't mess with node if search is currently matching
        this.node.style("stroke", function(n) {
          if (n.searched || neighboring(d, n)) { return "#555"; } else { return "#aaa" }
        })
          .style("stroke-width", function(n) {
            if (n.searched || neighboring(d, n)) { return 2.0; } else { return 1.0; }
        });

        // highlight the node being moused over
        return d3.select(this).style("stroke","yellow")
          .style("stroke-width", 10.0)
          .attr("r", 20);
      };

      // Given two nodes a and b, returns true if there is a link between them.
      // Uses linkedByIndex initialized in setupData
      const neighboring = (a, b) => this.linkedByIndex[a.id + "," + b.id] ||
        this.linkedByIndex[b.id + "," + a.id];

        // Mouseout function
      this.hideDetails = function() {
        if (!this.clicked) {
          // watch out - don't mess with node if search is currently matching
          this.node.style("stroke", function(n) { if (!n.searched) { return "#aaa"; } else { return "#555"; } })
            .style("stroke-width", function(n) { if (!n.searched) { return 1.0; } else { return 2.0; } })
            .attr("r", function(n) { if (!n.searched) { return n.radius; } else { return n.radius; } });
          if (this.link) {
            return this.link.attr("stroke", "#ddd")
              .attr("stroke-opacity", 0.8);
          }
        }
      };

      this.simulation.on("tick", () => {
        this.link
          .attr("x1", d => d.source.x)
          .attr("y1", d => d.source.y)
          .attr("x2", d => d.target.x)
          .attr("y2", d => d.target.y);

      this.node
        .attr("cx", d => d.x)
        .attr("cy", d => d.y);
      });
    }
  }
}
</script>

<style scoped>
.tooltip {
  position: absolute;
  display: 'none';
  min-height: '150px';
  -moz-border-radius:3px;
  border-radius: 3px;
  border: 2px solid #ef6c00;
  background: #fff;
  opacity: 1;
  color: #000;
  padding: 10px;
  width: 320px;
  font-size: 15px;
  z-index: 120;
}

.tooltip p.main {
  font-size: 15px;
  text-align: center;
  padding:0;
  margin:0;
}
</style>
