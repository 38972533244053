<template>
  <div>
    <div v-if="smartzoneColors">
      <v-chip-group column>
        <v-chip
          v-for="(color, key) in smartzoneColors"
          :key="key"
          :color="color"
          dark
        >
          <b>{{ key }}</b>
        </v-chip>
      </v-chip-group>
      <hr />
    </div>
    <v-card >
      <div id="mapTooltip"
        class="tooltip"
      />
    </v-card>
    <svg id="map" :height="height" :width="width">
      <g id="countiesG"
        @mouseover="showCountyTooltip"
        @mouseout="hideTooltip"
        @click="countyClick"/>
      <g id="pathsG" />
      <g id="mapNodesG"
        @mouseover="showTooltip"
        @mouseout="hideTooltip"
        @click="toggleTooltipClick"
      />
    </svg>
    <v-row v-if="Name === 'Michigan Network'">
      <v-col cols = "1"/>
      <v-col cols = "10">
        <v-card>
          <v-simple-table>
            <thead>
              <tr>
                <th><b>County</b></th>
                <th><b>Number of Startups</b></th>
                <th><b>Number of Investors</b></th>
                <th><b>Total Amount Raised</b></th>
                <!-- <th><b>Average Amount Raised</b></th> -->
                <th v-if="Name === 'Michigan Network'"><b>Population</b></th>
                <th v-if="Name === 'Michigan Network'"><b>Employment</b></th>
                <th v-if="Name === 'Michigan Network'"><b>GDP</b></th>
                <th v-if="Name === 'Michigan Network'"><b>Income</b></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="county in countyStats" :key="county.id">
                <td><b> {{ county.name }} </b></td>
                <td> {{ county.startups }} </td>
                <td> {{ county.investors }} </td>
                <td> {{ county.totalFunds }} </td>
                <!-- <td> {{ county.averageFunds }} </td> -->
                <td v-if="Name === 'Michigan Network'"> {{ county.population }} </td>
                <td v-if="Name === 'Michigan Network'"> {{ county.employment }} </td>
                <td v-if="Name === 'Michigan Network'"> {{ county.gdp }} </td>
                <td v-if="Name === 'Michigan Network'"> {{ county.income }} </td>
              </tr>
            </tbody>
          </v-simple-table>
          <hr />
          <v-btn id="countyReset" @click="counties = {}; statifyCounties()"> Reset </v-btn>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import * as d3 from "d3";
import * as topojson from "topojson-client";
import { Auth } from 'aws-amplify';
import { getData } from "../datafetch.js";
import { AUTH_ENABLED } from "../constants.js";

export default{
  name: "MapView",
  props: {
    nodes: Array,
    colors: Object,
    searchString: String,
    geo: Object,
    Name: String,
    timestamp: String,
  },
  data() {
    return{
      height: 600,
      width: 960,
      clicked: false,
      map: Object,
      counties: {},
      mapColors: Object,
      smartzoneColors: null,
      accessToken: null,
      econData: null,
      fipsMapping: null,
      countyStats: {},
    }
  },
  watch: {
    nodes: function() {
      this.map = this.MapObject(this.nodes);
      d3.select("#map").selectAll("circle");
    },
    searchString: function(){
      this.updateSearch(this.searchString);
    }
  },
  created: async function(){
    /**
      * On create, grab auth and initialize econ data/fips mapping if necessary
    **/
    if(this.Name === 'Michigan Network'){
      if (AUTH_ENABLED) {
        let token = await Auth.currentSession();
        this.accessToken = token["accessToken"];
      }
      this.econData = await getData('Michigan_Economics.json', this.accessToken);
      this.fipsMapping = await getData('fips_map.json', this.accessToken);
    }
  },
  mounted: async function(){
    /**
      * On mount, generate the map and set line color
    **/
    if(this.Name === 'Michigan Network'){
      this.mapColors = this.populateMapColors();
    }
    this.hideTooltip();
    await this.generateMap();
    this.map = this.MapObject(this.nodes);
    d3.select('#pathsG')
      .selectAll('path')
      .enter()
      .style('stroke','#0000FF')
      .exit();
  },
  methods: {
    populateMapColors(){
      /**
        * Set county fill colors based on smartzones
        * @return {String -> hexColor}: a mapping of fips to fill color
      **/
      this.smartzoneColors = {
        'Houghton/Hancock': '#e6194B',
        'Marquette': '#3cb44b',
        'Sault Ste. Marie': '#4363d8',
        'Muskegon': '#f58231',
        'Grand Rapids': '#911eb4',
        'Holland': '#42d4f4',
        //'Midland' = None,
        'Mount Pleasant': '#f032e6',
        'Port Huron': '#bfef45',
        'Lansing': '#fabed4',
        'Battle Creek': '#469990',
        'Kalamazoo': '#dcbeff',
        'Ann Arbor/Ypsilanti': '#9A6324',
        'Jackson': '#000075',
        'Adrian': '#800000',
        //'Rochester Hills' = '#808000',
        // 'Sterling Heights' = '#ffd8b1',
        // 'Troy' = '#fffac8',
        // 'Southfield' = '#a9a9a9',
        'Detroit': '#aaffc3',
        'Multiple Smartzones': '#ffe119',
      }
      const countyColors = {
        "26005": this.smartzoneColors['Holland'], //Allegan
        "26017": this.smartzoneColors['Mount Pleasant'], //Bay
        "26025": this.smartzoneColors['Battle Creek'], //Calhoun
        "26033": this.smartzoneColors['Sault Ste. Marie'], //Chippewa
        "26037": this.smartzoneColors['Multiple Smartzones'], //Clinton: Grand Rapids, Lansing
        "26045": this.smartzoneColors['Lansing'], //Eaton
        "26061": this.smartzoneColors['Houghton/Hancock'], //Houghton
        "26065": this.smartzoneColors['Multiple Smartzones'], //Ingham: Grand Rapids, Lansing
        "26073": this.smartzoneColors['Mount Pleasant'], //Isabella
        "26075": this.smartzoneColors['Jackson'], //Jackson
        "26077": this.smartzoneColors['Kalamazoo'], //Kalamazoo
        "26081": this.smartzoneColors['Grand Rapids'], //Kent
        "26083": this.smartzoneColors['Houghton/Hancock'], //Keweenaw
        "26091": this.smartzoneColors['Adrian'], //Lenawee
        "26093": this.smartzoneColors['Ann Arbor/Ypsilanti'], //Livingston
        "26099": this.smartzoneColors['Multiple Smartzones'], //Macomb: Sterling Heights, Troy
        "26103": this.smartzoneColors['Marquette'], //Marquette
        "26111": this.smartzoneColors['Multiple Smartzones'], //Midland: Midland, Mount Pleasant
        "26121": this.smartzoneColors['Muskegon'], //Muskegon
        "26125": this.smartzoneColors['Multiple Smartzones'], //Oakland: Rochester Hills, Troy, Southfield
        "26139": this.smartzoneColors['Holland'], //Ottawa
        "26145": this.smartzoneColors['Mount Pleasant'], //Saginaw
        "26147": this.smartzoneColors['Port Huron'], //St. Clair
        "26161": this.smartzoneColors['Ann Arbor/Ypsilanti'], //Washtenaw
        "26163": this.smartzoneColors['Detroit'], //Wayne
      }
      return countyColors;
    },
    countyClick(event) {
      /**
        * On county click, either add data to table or remove it
        * @param {WindowEvent} event: The mouse event of clicking on a count
      **/
      if(!this.clicked){
        let county = event.target.__data__.id;
        if(county in this.counties){
          delete this.counties[county];
        }
        else{
          const state = this.fipsMapping[county.toString().slice(0,2)];
          const countyName = event.target.__data__.properties.name;
          const displayText = countyName + " County, " + state;
          this.counties[county] = displayText;
        }
        this.statifyCounties();
      }
      else{
        this.clicked=false;
        this.hideTooltip();
      }
    },
    statifyCounties(){
      /**
        * Transforms the list of selected counties into a format more conducive
        * for the table display
      **/
      let counties = this.counties;
      let orgs = this.nodes.filter(function(node) {
        return node.county_id in counties;
      });
      // Create object with keys representing county FIPS
      let rtn = Object.keys(counties).reduce((accumulator, value) => {
        return {...accumulator, [value]: {'startups': 0, 'investors': 0, 'totalFunds': 0, 'averageFunds': 0}};
      }, {});
      orgs.forEach(function(org) {
        rtn[org.county_id].name = counties[org.county_id];
        if (org.role == "Startup"){
          rtn[org.county_id].startups += 1;
          rtn[org.county_id].totalFunds += org.amountRaised;
        }
        else if (org.role == "Investor"){
          rtn[org.county_id].investors +=1;
        }
      });
      var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      });
      for (const key in rtn){
        if (rtn[key].startups == 0 && rtn[key].investors == 0){
          rtn[key].name = counties[key];
        }
        else{
          rtn[key].averageFunds = rtn[key].totalFunds > 0 ? formatter.format((rtn[key].totalFunds)/(rtn[key].startups)) : formatter.format(0);
          rtn[key].totalFunds = formatter.format(rtn[key].totalFunds);
          if (this.Name === 'Michigan Network' && key in this.econData){
            let year = this.timestamp.split('-')[0];
            // Rubber band down to 2020 (highest year we have every metric for)
            if (year > '2020'){
              year = '2020';
            }
            rtn[key].employment = this.econData[key][year+'_employment'];
            rtn[key].gdp = this.econData[key][year+'_GDP'];
            rtn[key].income = this.econData[key][year+'_income'];
            rtn[key].population = this.econData[key][year+'_population'];
          }
        }
      }
      this.countyStats = rtn;
    },
    showTooltip(event) {
      /**
        * Shows the node tooltip when you hover over it
        * @param {WindowEvent} event: The event of hovering over the node
      **/
      if(!this.clicked){
        let tooltip = document.getElementById("mapTooltip");
        tooltip.innerHTML = event.target.__data__.tooltip;
        tooltip.style.display = 'block';
      }
    },
    showCountyTooltip(event) {
      /**
        * Shows the county tooltip when you hover over it
        * @param {WindowEvent} event: The event of hovering over the county
      **/
      if(!this.clicked){
        let tooltip = document.getElementById("mapTooltip");
        tooltip.innerHTML = '<p>' + event.target.__data__.properties.name + ' County</p>';
        tooltip.style.display = 'block';
      }
    },
    hideTooltip() {
      /**
        * Hides whatever tooltip is being shown
      **/
      if(!this.clicked){
        var tooltip = document.getElementById("mapTooltip");
        tooltip.innerHTML = '';
        tooltip.style.display = 'none';
      }
    },
    toggleTooltipClick(event){
      /**
        * Toggles the visibility of the tooltip
        * @param {WindowEvent} event: The event of clicking the tooltip
      **/
      if(this.clicked){
        this.clicked=false;
        this.hideTooltip();
      }
      else{
        this.clicked=true;
        this.showTooltip(event)
      }
    },
    updateSearch(searchTerm){
      /**
        * Updates the search string and styles matching nodes
        * @param {String} searchTerm: The string to match against node info
      **/
      let node = d3.select("#mapNodesG")
        .selectAll("circle");
      const searchRegEx = new RegExp(searchTerm.toLowerCase());
      const colors = this.colors;
      node.each(function(d) {
        let match;
        const element = d3.select(this);
        match = d.name.toLowerCase().search(searchRegEx);
        if ((searchTerm.length > 0) && (match >= 0)){
          element.style("fill", "#FFFF00")
            .style("stroke-width", 5.0)
            .style("stroke", "#555")
            .attr("r",20);
          return d.searched = true;
        }
        else {
          element.style("fill", d => colors[d.role])
            .style("stroke-width", 1.0)
            .attr("r", 5);
          return d.searched = false;
        }
      });
    },
    async generateMap(){
      /**
        * Generates the map visual (the united states layout) in layers in the svg element
      **/
      let us = this.geo;
      let mapColors = this.mapColors;
      let projection = d3.geoAlbersUsa()
          .scale(1280)
          .translate([this.width / 2, this.height / 2]);
      let path = d3.geoPath()
          .projection(projection);
      let svg = d3.select("#pathsG");
      svg.append("path")
        .datum(topojson.feature(us, us.objects.nation))
        .attr("d", path)
        .style("fill","none")
        .style("stroke","black")
        .style("stroke-width",1.25)
        .attr("class", "land-boundary");
      svg.append("path")
        .datum(topojson.mesh(us, us.objects.states, function(a, b) { return a !== b; }))
        .attr("d", path)
        .style("fill","none")
        .style("stroke","black")
        .style("stroke-width",1.25)
        .attr("class", "state-boundary");
      d3.select('#countiesG')
        .attr('class', 'counties')
        .selectAll('path')
        .data(topojson.feature(us, us.objects.counties).features)
        .enter()
        .append('path')
        .attr('d',path)
        .style('fill', d => d.id in mapColors ? mapColors[d.id] : "#FFFFFF")
        .style('fill-opacity',d => d.id in mapColors ? 0.75 : 0)
        .style('stroke','#CCCCCC')
        .style('stroke-opacity',0.7)
        .on('click', function() {
          if (!(d3.select(this).style('stroke') === 'rgb(0, 255, 255)')) {
            d3.select(this).style('stroke', '#00FFFF')
              .style('stroke-opacity', 1.0)
              .style('stroke-width', 2);
          }
          else{
            d3.select(this).style('stroke', '#CCCCCC')
              .style('stroke-opacity', 0.7)
              .style('stroke-width', 1);
          }
        })
        .on('mouseover', function() {
          //
          if (!(d3.select(this).style('stroke') === 'rgb(0, 255, 255)')) {
            d3.select(this)
              .style('stroke','#FF0000')
              .style('stroke-opacity', 1.0)
              .style('stroke-width', 2);
          }
        })
        .on('mouseout', function() {
          //
          if (!(d3.select(this).style('stroke') === 'rgb(0, 255, 255)')) {
            d3.select(this)
              .style('stroke','#CCCCCC')
              .style('stroke-opacity', 0.7)
              .style('stroke-width', 1);
          }
        });
    },
    MapObject(nodes){ //TODO: Rename this as it just handles the nodes
      /**
        * Displays the nodes over the map object
        * @param {Nodes[]} nodes: The list of nodes to be displayed on the map
      **/
      const colors = this.colors;
      let projection = d3.geoAlbersUsa()
          .scale(1280)
          .translate([this.width / 2, this.height / 2]);
      var populateData = async function(){
        // Populates and array of nodes with different field names/access patterns
        let rows = [];
        for (let k in nodes) {
              let node_obj={};
              node_obj['name']= nodes[k].name;
              node_obj['role']= nodes[k].role;
              node_obj['location']= nodes[k].location;
              node_obj['lat']=nodes[k].lat;
              node_obj['lng']=nodes[k].long;
              node_obj['city']=nodes[k].city;
              node_obj['fundsRaised']=nodes[k].fundsRaised;
              node_obj['created_at']= nodes[k].createdAt;
              node_obj['hyperlink']=nodes[k].hyperlink;
              node_obj['industry']=nodes[k].industry;
              node_obj['areaOfExpertise']=nodes[k].areaOfExpertise;
              node_obj['tooltip']=nodes[k].tooltip;
              rows.push(node_obj);
          }
        return rows;
      };
      var displaySites = function(nodes) {
        // Displays the nodes as circles atop the map
        let node = d3.select("#mapNodesG")
          .selectAll("circle")
          .data(nodes)
          .join("circle")
          .attr("cx", function(d) {
            return projection([d.lng, d.lat])[0];
          })
          .attr("cy", function(d) {
            return projection([d.lng, d.lat])[1];
          })
          .style('fill', function(d) {
            return colors[d.role]
          })
          .attr("r", 5)
          .style("stroke","black");
        node.exit()
          .transition().duration(200)
          .attr("r",1)
          .remove();
      };
      var init = async function(){
        // Populates the site data and adds zoom functionality to each of the
        // svg elements via d3
        let sitesData = await populateData();
        displaySites(sitesData);
        let svg = d3.select('#map');
        let g = d3.select('#pathsG');
        let countG = d3.select('#countiesG')
        let nodesG = d3.select('#mapNodesG')
        var zoom = d3.zoom()
          .scaleExtent([1, 8])
          .on('zoom', function(event) {
            g.selectAll('path')
              .attr('transform', event.transform);
            // TODO: Look up groups within groups for this
            countG.selectAll('path')
              .attr('transform', event.transform);
            nodesG.selectAll('circle')
              .attr('transform', event.transform)
              .attr('r', function() { return event.transform.k > 1 ? 5/event.transform.k : 5})
              .style('stroke-width', function() { return event.transform.k > 1 ? 1/event.transform.k : 1});
        });
        d3.select('#countyReset')
          .on('click', () => {
            d3.select('#countiesG')
              .selectAll('path')
              .style('stroke','#CCCCCC')
              .style('stroke-opacity', 0.7)
              .style('stroke-width', 1);
          });
        svg.call(zoom);
      };
      init()
    }
  }
}

</script>

<style scoped>
.tooltip {
  position: absolute;
  display: 'none';
  min-height: '150px';
  -moz-border-radius:3px;
  border-radius: 3px;
  border: 2px solid #ef6c00;
  background: #fff;
  opacity: 1;
  color: #000;
  padding: 10px;
  width: 320px;
  font-size: 15px;
  z-index: 120;
}

.tooltip p.main {
  font-size: 15px;
  text-align: center;
  padding:0;
  margin:0;
}
</style>
