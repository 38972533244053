<template>
  <div>
    <v-row>
      <v-col
        cols="0"
        lg="1"
      />
      <v-col :cols="tooLarge ? 3 : 4">
        <multiselect
          v-model="value"
          :options="filterOptions"
          :multiple="true"
          :close-on-select="false"
          :clear-on-select="false"
          label="value"
          track-by="value"
          placeholder="Filter here by role, industry, or location"
          @input="updateNodes"
        />
        <v-chip-group
          v-model="activeFilters"
          mandatory
          active-class="primary--text"
          >
          <v-chip
            v-for="filter in filters"
            :key="filter"
            :value="filter"
          >
            {{ capitalize(filter) }}
          </v-chip>
        </v-chip-group>
      </v-col>
      <v-col :cols="tooLarge ? 2 : 4">
        <v-text-field
          v-model="searchString"
          label="Search here"
          outlined
        />
      </v-col>
      <v-col
        v-if="tooLarge"
        cols="3"
      >
        <multiselect
          v-model="missingValue"
          placeholder="Add nodes here"
          :multiple="true"
          :close-on-select="false"
          :clear-on-select="false"
          :options="missingNames"
          @input="updateNodes"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="0"
        lg="1"
      />
      <v-col cols="8">
        <v-slider
          v-if="timeable"
          v-model="sliderValue"
          color= 'orange darken-3'
          :tick-labels="timeLabels"
          :max="timeLabels.length-1"
          step="1"
          ticks="always"
          tick-size="5"
          @change="updateTimestamp"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="0"
        lg="1"
      />
      <v-col cols="8">
        <v-card>
          <v-tabs v-model="tab"
            background-color="orange darken-3"
            dark
            dense>
            <v-tab> Network </v-tab>
            <v-tab> Map </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item v-for="item in items" :key="item">
              <div v-if="item=='Network'">
                <ChipKey
                  v-if="options && colors"
                  :options="options"
                  filter="role"
                  :colors="colors"
                />
                <hr />
                <NetworkView
                  v-if="tab==0"
                  :data="networkData"
                  :colors="colors"
                  :searchString="searchString"
                />
                <h6>
                  Note: Network may be abridged for clarity and responsiveness
                </h6>
              </div>
              <div v-else-if="item=='Map' && geo">
                <ChipKey
                  v-if="options && colors"
                  :options="options"
                  filter="role"
                  :colors="colors"
                />
                <hr />
                <MapView
                  v-if="geoNodes"
                  :nodes="geoData"
                  :colors="colors"
                  :geo="geo"
                  :searchString="searchString"
                  :Name="Name"
                  :timestamp="timestamp"
                />
                <h3 v-else>
                  No mapping data available.
                </h3>
              </div>
              <div v-else-if="item=='Table'">
                <TableView
                  :metricsFile="metrics"
                  :timestamp="timestamp"
                />
                <h6>
                  Note: This table does not react to filters due to computational complexity
                </h6>
              </div>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
      <v-col
        cols="4"
        lg="2">
        <CytoMetrics
          :cytoData="cytoData"
          :scale="scale"
          :Name="Name"
          @sorted-nodes="updateSortedNodes"
          class="pb-4"
        />
        <v-card>
          <v-card-subtitle><b>Interconnectivity</b>: {{ (1-this.modularity).toFixed(2) }}</v-card-subtitle>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import NetworkView from "../components/NetworkView.vue";
import MapView from "../components/MapView.vue";
import TableView from "../components/TableView.vue";
import ChipKey from "../components/ChipKey.vue";
import CytoMetrics from "../components/CytoMetrics.vue";
import Multiselect from 'vue-multiselect';
import jLouvain from "../assets/jLouvain.js";

export default{
  name: 'VisualizationTabs',
  props: {
    SchemaJson: Object,
    NetworkJson: Object,
    MapJson: Object,
    GeoJson: Object,
    MetricsJson: Object,
    Name: String
  },
  components: {
    Multiselect,
    NetworkView,
    MapView,
    TableView,
    ChipKey,
    CytoMetrics
  },
  data(){
    return{
      tab: null,
      items: ['Network', 'Map', 'Table'],
      value: this.SchemaJson.defaultValue ? this.SchemaJson.defaultValue : [],
      missingValue: [],
      sliderValue: 0,
      options: this.SchemaJson.options,
      filters: this.SchemaJson.filters,
      activeFilters: [this.SchemaJson.filters[0]],
      searchString: "",
      timeable: this.SchemaJson.timeable,
      timeLabels: this.SchemaJson.timelabels,
      timeValues: this.SchemaJson.timevalues,
      timedBy: this.SchemaJson.timedBy,
      linksTimeable: this.SchemaJson.linksTimeable,
      linksTimedBy: this.SchemaJson.linksTimedBy,
      colors: this.SchemaJson.colors,
      scale: this.SchemaJson.scaleBy,
      nodes: Object.freeze(this.NetworkJson.nodes),
      links: Object.freeze(this.NetworkJson.links),
      linksByTarget: Array,
      geoNodes: Object,
      networkData: Array,
      geoData: Array,
      cytoData: [],
      tooLarge: false,
      missingNodes: [],
      geo: this.GeoJson,
      metrics: this.MetricsJson,
      modularity: 0,
    }
  },
  computed: {
    timestamp(){
      return this.timeValues[this.sliderValue];
    },
    filterOptions(){
      let currFilt = this.activeFilters;
      if (this.options[0] == undefined){
        return null
      }
      else{
        return this.options.filter(function(option){
          return currFilt.includes(option.filter);
        })
      }
    },
    missingNames(){
      return this.missingNodes.map(x => x.name);
    },
    addedNodes(){
      let values = this.missingValue;
      return this.missingNodes.filter(function(node){
        return values.includes(node.name);
      })
    }
  },
  mounted: function(){
    /**
      * On mount, freeze the links and geonodes to prevent overriding the source data;
      * create a grouping of links by target, and finally updateTimestamp
    **/
    if(this.MapJson){
      this.geoNodes = Object.freeze(this.MapJson.nodes);
    }
    else{
      this.geoNodes = null;
    }
    this.freezeLinks();
    this.linksByTarget = this.groupLinksByTarget();
    this.updateTimestamp();
  },
  methods: {
    groupLinksByTarget(){
      /**
        * Creates an object mapping for each node node to all the links that point to it
        * @returns An object of structure {targetNodeId0: [link, ...], targetNodeId1..., }
      **/
      let rtn = {};
      Object.keys(this.links).forEach(link=>{
        let curr = JSON.parse(JSON.stringify(this.links[link]));
        curr.lookup = parseInt(link);
        if (curr.target in rtn){
          rtn[curr.target].push(curr);
        }
        else{
          rtn[curr.target] = [curr];
        }
      })
      return rtn;
    },
    updateSortedNodes(payload){
      /**
        * Takes in the sorted nodes from CytoMetrics and updates the currNodes to be
        * passed to other components
        * @param {Node[]} payload: the sorted nodes coming back from CytoMetrics
      **/
      let currNodes = payload;
      this.tooLarge = currNodes.length > 500;
      if (this.tooLarge){
        this.missingNodes = currNodes.slice(501);
        currNodes = currNodes.slice(0,500);
        currNodes.push(...this.addedNodes);
      }
      let neighbors = this.getStartupNeighbors(currNodes);
      currNodes.push(...neighbors);
      currNodes = [...new Set(currNodes)];
      let currLinks = this.filterLinks(currNodes);
      this.networkData = [currNodes, currLinks];
      let currGeoNodes = this.filterNodes(this.geoNodes);
      let geoNeighbors = this.getStartupNeighbors(currGeoNodes);
      let geoInvNeighbors = this.getInvestorNeighbors(currGeoNodes);
      currGeoNodes.push(...geoNeighbors);
      currGeoNodes.push(...geoInvNeighbors);
      currGeoNodes = this.filterNones(currGeoNodes);
      this.geoData = currGeoNodes;
      var nodesMin = currNodes.map((x) => x.id);
      var community = jLouvain().nodes(nodesMin).edges(currLinks);
      var community_assignment_result = community();
      this.modularity = community_assignment_result && community_assignment_result['modularity']
        ? community_assignment_result['modularity'].toFixed(4)
        : 1;
    },
    freezeLinks(){
      /**
        * Freezes the links and instantiates any special fields a link may need
      **/
      if(this.Name == "Michigan Network"){
        this.links.forEach((link)=> {
          link.lead = {leadNow : "false"}
        });
      }
      this.links.forEach(Object.freeze);
    },
    capitalize(inputString){
      return inputString.charAt(0).toUpperCase() + inputString.slice(1);
    },
    filterNones(nodes){ // TODO: Rename this to "filterGeoless"
      return nodes.filter(function(node){
        return node['lat'] != null;
      })
    },
    updateTimestamp(){
      /**
        * Updates the link-derived fields for every node that has links
        * (utilizing linksByTarget); then calls updateNodes to reapply filters
        * based on these new values
      **/
      this.missingValue = [];
      const mappedToOther = ['Secondary Market', 'Equity Crowdfunding', 'Debt Financing',
        'Convertible Note', 'Corporate Round']
      if(this.Name == "Michigan Network"){
        var timestamp = this.timestamp;
        var elem = this.linksTimedBy;
        for (let target in this.linksByTarget){
            let amount = 0;
            let fundingRound = "unfunded";
            let highestDate = "00-00-0000";
            let fundingRoundDates = [];
            for(let j in this.linksByTarget[target]){
              //check if link is valid under timestamp
              let date = this.linksByTarget[target][j][elem];
              let components = date.split("/");
              let compare = [components[2],components[0],components[1]].join("-");
              // then, if so, parse amount as a float and add it to sum
              if (compare <= timestamp){
                if (!fundingRoundDates.includes(compare)){
                  fundingRoundDates.push(compare);
                  amount += parseFloat(this.linksByTarget[target][j].amountRaised);
                  if (compare > highestDate){
                    highestDate = compare;
                    fundingRound = this.linksByTarget[target][j].investmentType;
                  }
                }
              }
            }
            this.nodes[target].amountRaised = amount;
            if (mappedToOther.includes(fundingRound)){
              this.nodes[target].fundingRound = 'Other';
            }
            else{
              this.nodes[target].fundingRound = fundingRound;
            }
            if (fundingRound !== "Unfunded"){
              this.assignLeadLink(target, highestDate);
            }
            this.updateTooltip(this.nodes[target]);
        }
      }
      this.updateNodes();
    },
    assignLeadLink(target, highestDate){
      /**
        * Updates the "leadNow" field of every link pointing at the target node
        * @param {Number} target: The id of the target node
        * @param {Date} highestDate: The highest date appearing in links targeting
        * the target that is still lower than the timestamp value
      **/
      for (let link in this.linksByTarget[target]){
        let curr = this.linksByTarget[target][link];
        let date = this.linksByTarget[target][link][this.linksTimedBy];
        let components = date.split("/");
        let compare = [components[2],components[0],components[1]].join("-");
        // TODO: I could replace this with an initial assignment to false then
        // a compound if to set to true
        if (compare === highestDate){
          if (curr.isLead === 'true') {
            this.links[curr.lookup].lead.leadNow = 'true';
          }
          else {
            this.links[curr.lookup].lead.leadNow = 'false';
          }
        }
        else {
          this.links[curr.lookup].lead.leadNow = 'false';
        }
      }
    },
    updateTooltip(node){
      /**
        * Updates the tooltip of a given node based on the current link-derived fields
        * as these change between timestamps
        * @param {Node} node: The node to update the tooltip for
      **/
      var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      });
      if (node.role=="Startup"){
        let content = '<p class="main">'+ node.name + ',' + node.city + '</p>';
        content += '<hr class="tooltip-hr">';
        content += '<p class="main">';
        if('realRound' in node){
          content += node.realRound;
        }
        else{
          content += node.fundingRound;
        }
        content += ',' + formatter.format(node.amountRaised) + '</p>';
        content += '<hr class="tooltip-hr">';
        content += '<p class="main">' + node.industry + '</p>';
        node.tooltip = content;
      }
    },
    updateNodes(){
      /**
        * Resets the searchString; sets currNodes based on current filters; passes
        * currNodes to CytoMetrics for network calculations
      **/
      this.searchString = "";
      let currNodes = this.filterNodes(this.nodes);
      let neighbors = this.getStartupNeighbors(currNodes);
      currNodes.push(...neighbors);
      currNodes = [...new Set(currNodes)];
      this.cytoData = JSON.parse(
        JSON.stringify(
          [currNodes,this.filterLinks(currNodes)]
        )
      );
    },
    checkForAll(){
      /**
        * If a filter is marked 'All', ignore it
        * TODO: I don't think this is being called atm. Delete?
      **/
      var val = this.value;
      for (let item of val) {
        if(item["filter"] == "All") {
          this.value = item;
        }
      }
    },
    filterNodes(nodes){
      /**
        * Filters the list of all nodes, only returning those that are repesented
        * by the current filter and timestamp (if applicable)
        * @param {Node[]} nodes: The list of all ndoes
        * @return {Node[]} result: The list of nodes that match the current user-selected filter and timestamp
      **/
      let filteredNodes = nodes;
      let result;
      // filter by time if timeable first
      if(this.timeable){
        var timestamp = this.timestamp;
        var elem = this.timedBy;
        result = filteredNodes.filter(function(n) {
          var date = n[elem];
          if(date){//if date has a valid value do comparison, else return true
            let components = date.split("/");
            let compare = [components[2],components[0],components[1]].join("-");
            return compare <= timestamp;
          }
          else { return true; }
        })
      }
      if (result) {
        filteredNodes = result;
      }
      if (this.value.length > 0 && this.value[0]["filter"] != "All"){
        let value = {};
        this.filters.forEach(function (item){
          value[item] = [];
        })
        this.value.forEach(function (item){
          value[item.filter].push(item.value);
        })
        this.filters.forEach(function (item){
          if(value[item].length > 0){
            result = filteredNodes.filter(function(n){
              if(n[item]){
                let filts = n[item].split("; ");
                return value[item].some(element=>(filts.includes(element)));
              }
              else{
                return false;
              }
            })
          }
          if(result){
            filteredNodes = result;
          }
        })
        return result;
      }
      else{ return filteredNodes }
    },
    getStartupNeighbors(currNodes){
      /**
        * Returns all nodes that are direct neighbors of startup nodes
        * @param {Node[]} currNodes: The list of nodes that neighbors will be derived from
        * @return {Node[]}: The list of nodes containing all neighbors of startup nodes in currNodes
      **/
      var mapOfNodes = this.mapNodes(currNodes);
      var allNodes = this.mapNodes(this.nodes);
      var rtn = [];
      this.links.forEach((link) => {
        // If not both endpoints in filtered view
        if(!(mapOfNodes.has(link.source) && mapOfNodes.has(link.target))){
          // If source in view and source is startup, add target
          if(mapOfNodes.has(link.source) && mapOfNodes.get(link.source).role === 'Startup'){
            rtn.push(allNodes.get(link.target));
          }
          // If target in view and target is startup, add source
          else if(mapOfNodes.has(link.target) && mapOfNodes.get(link.target).role === 'Startup'){
            rtn.push(allNodes.get(link.source));
          }
        }
      });
      return rtn;
    },
    getInvestorNeighbors(currNodes){
      /**
        * Returns all nodes that are direct neighbors of investor nodes
        * @param {Node[]} currNodes: The list of nodes that neighbors will be derived from
        * @return {Node[]}: The list of nodes containing all neighbors of investor nodes in currNodes
      **/
      var mapOfNodes = this.mapNodes(currNodes);
      var allNodes = this.mapNodes(this.nodes);
      var rtn = [];
      this.links.forEach((link) => {
        // If not both endpoints in filtered view
        if(!(mapOfNodes.has(link.source) && mapOfNodes.has(link.target))){
          // If source in view and source is startup, add target
          if(mapOfNodes.has(link.source) && mapOfNodes.get(link.source).role === 'Investor'){
            rtn.push(allNodes.get(link.target));
          }
          // If target in view and target is startup, add source
          else if(mapOfNodes.has(link.target) && mapOfNodes.get(link.target).role === 'Investor'){
            rtn.push(allNodes.get(link.source));
          }
        }
      });
      return rtn;
    },
    filterLinks(currNodes){
      /**
        * Filter all links to only return the links such that both endpoints are
        * present in currNodes
        * @param {Node[]} currNodes: The list of nodes that will be used to filter links
        * @return {Link[]} result: The list of links that connect the nodes present in currNodes
      **/
      var mapOfNodes = this.mapNodes(currNodes);
      var includedLinks = this.links.filter(function(l) {
        return (mapOfNodes.has(l.source) && mapOfNodes.has(l.target));
      });
      if(this.linksTimeable){
        var timestamp = this.timestamp;
        var elem = this.linksTimedBy;
        includedLinks = includedLinks.filter(function(n) {
          var date = n[elem];
          if(date){//if date has a valid value do comparison, else return true
            let components = date.split("/");
            let compare = [components[2],components[0],components[1]].join("-");
            return compare <= timestamp;
          }
          else { return true; }
        })
      }
      let result = [];
      for (let i = 0; i < includedLinks.length; i++){
        result.push({...includedLinks[i]});
      }
      return result;
    },
    mapNodes (nodes) {
      /**
        * Creates a map of the nodes for indexing purposes
        * @param {Node[]} nodes: List of nodes
        * @return {Map{id->Node}}: The map of nodes such that they can be accessed by their id
      **/
      var nodesMap = new Map();
      nodes.forEach(n => nodesMap.set(n.id, n));
      return nodesMap;
    },
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
